<template>
  <div class="container" style="padding: 20px 10px">
            <el-row :gutter="20">
              <el-col :span="6">
                <div class="grid-content bg-purple">



                  <el-card class="box-card" always :body-style="{ padding: '0px' }">
                    <template #header>
                      <div class="card-header">
                        <span>身份证 | 户口本 | 港澳台居民居住证</span>
                      </div>
                    </template>
                    <el-table :data="tableData" style="width: 100%" border>
                      <el-table-column prop="id_num" label="证件号码"  >
                        <template #default="scope">
                          <el-button size="small" :type="scope.row.type" plain @click="copyButton(scope.$index, scope.row, 1)">{{scope.row.id_num}}</el-button>
                        </template>
                      </el-table-column>

                      <el-table-column prop="birthday" label="出生日期" width="100"/>
                      <el-table-column prop="birthday" label="性别" width="50">
                        <template #default="scope">
                          {{scope.row.sex == 1 ? '男' : '女'}}
                        </template>
                      </el-table-column>
                    </el-table>
                  </el-card>

                </div>
              </el-col>
              <el-col :span="4">
                <div class="grid-content bg-purple">

                  <el-card class="box-card" always :body-style="{ padding: '0px' }">
                    <template #header>
                      <div class="card-header">
                        <span>港澳居民来往内地通行证</span>
                      </div>
                    </template>
                    <el-table :data="tableDataHKTravelPermit" style="width: 100%" border>
                      <el-table-column prop="id_num" label="证件号码"  >
                        <template #default="scope">
                          <el-button size="small" :type="scope.row.type" plain @click="copyButton(scope.$index, scope.row, 23)">{{scope.row.id_num}}</el-button>
                        </template>
                      </el-table-column>

                    </el-table>
                  </el-card>
                </div>

                <div style="margin-top: 10px"></div>

                <el-card class="box-card" always :body-style="{ padding: '0px' }">
                  <template #header>
                    <div class="card-header">
                      <span>台湾居民来往内地通行证</span>
                    </div>
                  </template>
                  <el-table :data="tableDataTWTravelPermit" style="width: 100%" border>
                    <el-table-column prop="id_num" label="证件号码"  >
                      <template #default="scope">
                        <el-button size="small" :type="scope.row.type" plain @click="copyButton(scope.$index, scope.row, 24)">{{scope.row.id_num}}</el-button>
                      </template>
                    </el-table-column>

                  </el-table>
                </el-card>

              </el-col>
              <el-col :span="3">
                <div class="grid-content bg-purple">

                  <el-card class="box-card" always :body-style="{ padding: '0px' }">
                    <template #header>
                      <div class="card-header">
                        <span>护照</span>
                      </div>
                    </template>
                    <el-table :data="tableDataPassport" style="width: 100%" border>
                      <el-table-column prop="id_num" label="证件号码"  >
                        <template #default="scope">
                          <el-button size="small" :type="scope.row.type" plain @click="copyButton(scope.$index, scope.row, 2)">{{scope.row.id_num}}</el-button>
                        </template>
                      </el-table-column>

                    </el-table>
                  </el-card>

                </div>
              </el-col>
              <el-col :span="10">
                  <el-form ref="form" label-width="80px">
                    <el-form-item label="所在地">

                      <el-select v-model="province" placeholder="省" style="width: 180px">
                        <el-option
                                v-for="item in provinceQuery"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                        </el-option>
                      </el-select>
                      <el-select v-model="city" placeholder="市" style="width: 180px">
                        <el-option
                                v-for="item in cityQuery"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                        </el-option>
                      </el-select>
                      <el-select v-model="area" placeholder="区" style="width: 160px">
                        <el-option
                                v-for="item in areaQuery"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item label="年龄">
                      <el-col :span="6" style="padding: 0">

                        <el-autocomplete
                                v-model="age"
                                :fetch-suggestions="querySearch"
                                clearable
                                class="inline-input w-50"
                                placeholder="年龄"
                                @select="changeAge"
                                @change="changeAge"
                        />
                      </el-col>
                    </el-form-item>
                    <el-form-item label="出生日期">
                      <el-col :span="6" style="padding: 0">
                        <el-config-provider :locale="locale">

                          <el-date-picker type="date"
                                          placeholder="出生日期"
                                          v-model="birth"
                                          value-format="YYYY-MM-DD"
                                          :disabled-date="offlineDateAfter"
                                          @change="changeAge"
                                          style="width: 100%;"></el-date-picker>
                        </el-config-provider>
                      </el-col>
                    </el-form-item>
                    <el-form-item label="生效天数">
                      <el-col :span="6" style="padding: 0">

                        <el-input v-model="valid_days"
                                  @change="changeAge"
                                  placeholder="生效天数(天)" />
                      </el-col>
                    </el-form-item>
                    <el-form-item>
                      <el-button type="primary" @click="onSubmit">立即生成</el-button>
                    </el-form-item>


                    <el-divider />


                    <el-form-item>
                      <el-col :span="24" style="padding: 0">
                        <div class="grid-content bg-purple">
                          <el-card class="box-card" always :body-style="{ padding: '0px' }">
                            <template #header>
                              <div class="card-header">
                                <span>身份证解析</span>
                              </div>
                            </template>
                            <el-table :data="idNumInfo" style="width: 100%" border>
                              <el-table-column prop="id_num" label="证件号码">
                                <template #default="scope">
                                  <el-input v-model="scope.row.idNum"
                                            @blur="submitIdNum" placeholder="请输入需解析的证件号" />
                                </template>
                              </el-table-column>

                              <el-table-column prop="birth" label="出生日期" width="100"/>
                              <el-table-column prop="age" label="年龄" width="100"/>
                              <el-table-column label="性别" width="50">
                                <template #default="scope">
                                  {{scope.row.sex}}
                                </template>
                              </el-table-column>
                            </el-table>
                          </el-card>
                        </div>
                      </el-col>
                    </el-form-item>


                  </el-form>
              </el-col>
            </el-row>

  </div>
</template>

<script>

  import zhCn from "element-plus/lib/locale/lang/zh-cn";
  import copy from 'copy-to-clipboard';
  import { ElNotification } from 'element-plus'
  import { ElMessage } from 'element-plus'
  import { h } from 'vue'


  import {idNumShow, getArea, getIdNumInfo, otherIdNumShow} from '/src/api/request'
  export default {
    name: 'App',
    components: {
    },

    data() {
      return {
        locale: zhCn,
        provinceQuery:[],
        cityQuery:[],
        areaQuery:[],
        tableData:[],
        tableDataPassport:[],
        tableDataHKTravelPermit:[],
        tableDataTWTravelPermit:[],

        tableDataDefault:[],
        tableDataMax:[],
        tableDataMin:[],
        province:"",
        city:'',
        area:"",
        age: 18,
        ageApi: '',
        ageMax: '',
        ageMin: '',
        restaurants: [],
        birth: '',
        valid_days: '',
        idNumInfo : [
          {
            type:'success',
            idNum:'',
            sex:'',
            birth: '',
            age: '',
          }
        ],
      }
    },
    created(){
      var that = this

      this.provinceQuery = JSON.parse(localStorage.getItem('areaList'))
      if(!this.provinceQuery){
        getArea({}, response => {
          this.provinceQuery = response
          this.province = 1145
          localStorage.setItem('areaList', JSON.stringify(response))
          setTimeout(function(){
            that.getIdNum()
          }, 100)
        })
      }else{
        this.province = 1145
        setTimeout(function(){
          that.getIdNum()
        }, 100)
      }

    },
    mounted(){
      this.restaurants = this.loadAll();

    },
    watch:{
      // 监听省级
      province:function(id) {
        this.cityQuery=[]
        this.areaQuery =[]
        this.city=""
        this.area=""
        this.provinceQuery.forEach((item) => {
          if (id === item.id) {
            this.cityQuery = item.childlist
          }
        })
        try{
          this.cityQuery.forEach((item) => {
            this.city = item.id
            throw new Error('')
          })
        }catch(e){
          e.message
        }
      },
      // 监听市级
      city:function(id) {
        this.areaQuery =[],
                this.area=""
        this.cityQuery.forEach((item) => {
          if (id === item.id) {
            this.areaQuery = item.childlist
          }
        })
        try{
          this.areaQuery.forEach((item) => {
            this.area = item.id
            throw new Error('')
          })
        }catch(e){
          e.message
        }
      },
      age:function(age){
        if(age !== '') this.birth = ''
      },
      birth:function(birth){
        if(birth !== '') this.age = ''
      },


    },
    methods: {
      offlineDateAfter(time){
        let nowDate = (new Date()).getTime();
        let three = 70*365*24*3600*1000;
        let threeMonths = nowDate - three;
        return time.getTime() > Date.now()
        // return time.getTime() > Date.now() || time.getTime() < threeMonths
      },

      // 提交生成证件号
      onSubmit() {
        if(this.birth === null && this.age === ''){
          this.age = 1
        }
        this.getIdNum()
      },
      // copy
      // 1=最大,2=最小,3=常用,4=默认
      copyButton(index, row, type) {
        switch(type){
          case 1:
            row.type = 'warning'
            this.tableData[index] = row
            break;
          case 2:
            row.type = 'warning'
            this.tableDataPassport[index] = row
            break;
          case 23:
            row.type = 'warning'
            this.tableDataHKTravelPermit[index] = row
            break;
          case 24:
            row.type = 'warning'
            this.tableDataTWTravelPermit[index] = row
            break;
        }

        copy(row.id_num)

        ElMessage({
          message: '复制成功',
          type: 'success',
          duration: 1000,
        })
      },
      // API-获取证件号列表
      getIdNum(){
        var params = new URLSearchParams();
        params.append('area_id', this.area);
        params.append('age', this.age);
        params.append('birthday', this.birth);
        params.append('valid_day', this.valid_days);

        otherIdNumShow(params, response => {
          this.tableData = response.half_list
          this.tableDataPassport = response.passport_list
          this.tableDataHKTravelPermit = response.hk_travel_permit_list
          this.tableDataTWTravelPermit = response.tw_travel_permit_list

          this.tableData.forEach((item, index)=>{
            this.ageApi = item.age
            item.type = 'success'
            this.tableData[index] = item
          })
          this.tableDataPassport.forEach((item, index)=>{
            item.type = 'success'
            this.tableDataPassport[index] = item
          })
          this.tableDataHKTravelPermit.forEach((item, index)=>{
            item.type = 'success'
            this.tableDataHKTravelPermit[index] = item
          })
          this.tableDataTWTravelPermit.forEach((item, index)=>{
            item.type = 'success'
            this.tableDataTWTravelPermit[index] = item
          })

        })
      },
      // 下拉选择年龄
      loadAll() {
        return [
          { "value": "1"},
          { "value": "15"},
          { "value": "18"},
          { "value": "60"},
          { "value": "65"},
          { "value": "70"},
        ];
      },
      querySearch(queryString, cb) {
        cb(this.restaurants)

      },
      // 年龄选择触发
      changeAge(item) {
        this.getIdNum()
      },

      // 解析证件信息
      submitIdNum(){
        this.getIdNumInfo(this.idNumInfo[0].idNum)
      },
      getIdNumInfo(idNum){
        if(idNum){
          getIdNumInfo({idNum:idNum}, res => {
            console.log(res)
            let data = res
            this.idNumInfo[0].sex = data.sex == 1 ? '男' : '女'
            this.idNumInfo[0].birth = data.birth
            this.idNumInfo[0].age = data.age
          })
        }
      },
    }
  }
</script>

<style>
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: black;

    background: linear-gradient(-180deg, #BCC5CE 0%, #929EAD 98%), radial-gradient(at top left, rgba(255,255,255,0.30) 0%, rgba(0,0,0,0.30) 100%);
    background-blend-mode: screen;

    height: 100vh;
  }
  .el-form-item__label{
    color: black !important;
  }
  .el-form-item__content{
    display: flex;
    flex-flow: row nowrap;
  }
  .el-card__header{
    border: 0;
  }
  .container{
    /*padding: 20px 10px;*/
  }
  .el-card__header{
    padding: calc(var(--el-card-padding) - 12px) calc(var(--el-card-padding) - 10px);
    border-bottom: 1px solid var(--el-card-border-color);
    box-sizing: border-box;
    font-size: 14px;

    line-height: 18px;
  }
  .el-card {
    border: none;
  }
  .el-table--group, .el-table--border{
    border: none;
  }


</style>
