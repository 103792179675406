import axios from "../api/index"; //这里导入的是前面封装好的axios文件

// 版本信息
export const toolVersion = (data, ret)=>{
    return axios.request({
        url:"/api/index/toolVersion",
        method:'POST',
        data:data,
    }, ret)
}
// 四天一组
export const fourDay = (data, ret)=>{
    return axios.request({
        url:"/api/index/fourDay",
        method:'POST',
        data:data,
    }, ret)
}
// 证件列表
export const idNumShow = (data, ret)=>{
    return axios.request({
        url:"/api/index/idNumShow",
        method:'POST',
        data:data,
    }, ret)
}
export const otherIdNumShow = (data, ret)=>{
    return axios.request({
        url:"/api/index/otherIdNumShow",
        method:'POST',
        data:data,
    }, ret)
}

// 解析证件信息
export const getIdNumInfo = (data, ret)=>{
    return axios.request({
        url:"/api/index/getIdNumInfo",
        method:'POST',
        data:data,
    }, ret)
}

// 省市区 带编码
export const getArea = (data, ret)=>{
    return axios.request({
        url:"/api/index/getArea",
        method:'POST',
        data:data,
    }, ret)
}