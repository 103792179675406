
import { createApp } from 'vue'
import axios from 'axios'
import App from './App.vue'
import installElementPlus from './plugins/element'
import router from './router'
import store from './store'
import 'dayjs/locale/zh-cn' // 日期周几中文

// 用户指引
// import intro from 'intro.js' // introjs库
// import 'intro.js/introjs.css' // introjs默认css样式
// import 'intro.js/themes/introjs-modern.css' // introjs主题


// 引入icon https://element-plus.org/zh-CN/component/icon.html#%E6%B3%A8%E5%86%8C%E6%89%80%E6%9C%89%E5%9B%BE%E6%A0%87
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

const app = createApp(App).use(store).use(router)

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}

app.config.globalProperties.$axios = axios;
installElementPlus(app)
app.mount('#app')