import { createRouter, createWebHashHistory } from 'vue-router'
import Index from '../views/Index'
import Home from '../views/HomeView'
import otherIdNum from '../views/otherIdNum'

const routes = [
  {
    path: '/',
    name: 'index',
    component: otherIdNum
    // component: Home
  },
  {
    path: '/home',
    name: 'home',
    component: Home
  },
  {
    path: '/otherIdNum',
    name: 'otherIdNum',
    component: otherIdNum
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/date',
    name: 'date',
    component: () => import('../views/Date.vue')
  },
  {
    path: '/apitest',
    name: 'apitest',
    component: () => import('../views/ApiTest.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
