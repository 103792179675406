import { createStore } from 'vuex'

export default createStore({
  state: {
    version: '',
    openTab:[
      // {route: '/', name: '证件号码生成'},
      {route: '/otherIdNum', name: '其他证件类型'},
      {route: '/home', name: '证件号码生成'},
      {route: '/about', name: '免责声明'},
      // {route: '/apitest', name: '接口测试'},
      // {route: '/date', name: '日历'},
    ],//所有打开的路由
    activeIndex:'/otherIdNum' , //也可以是默认**路径；**状态
    // activeIndex:'/home' , //也可以是默认**路径；**状态

  },
  getters: {
  },
  mutations: {
    // 添加tabs
    add_tabs (state, data) {
      this.state.openTab.push(data);
    },
    // 删除tabs
    delete_tabs (state, route) {
      let index = 0;
      for (let option of state.openTab) {
        if (option.route === route) {
          break;
        }
        index++;
      }
      this.state.openTab.splice(index, 1);
    },
    // 设置当前**的tab
    set_active_index (state, index) {
      this.state.activeIndex = index;
    },
    setVersion(state, value){
      this.state.version = value
    }
  },
  actions: {
  },
  modules: {
  }
})
